@import "scss/mixins/material-palette";
@import "scss/mixins/skinning";
@import "scss/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "scss/theming";
@import "scss/custom-bootstrap";
@import "scss/core";

.blinking {
  animation: blinkingText 2.0s infinite;
}

@keyframes blinkingText {
  100% {
    color: #FF0000;
  }
  20% {
    color: transparent;
  }
}

// TODO: remove after adding new image-viewer
//.image-gallery-2 {
//  width: 400px !important;
//  position: relative !important;
//  height: 305px !important;
//  margin-top: 45px !important;
//  margin-bottom: 0px !important;
//}

.button-tooltip {
  background-color: Transparent;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  outline: 1px;
  padding: 2px;
}

.popover-header {
  font-size: 13px;
  background-color: #e6f5ff;
}

.popover-body {
  font-size: 12px;
  background-color: #f0f8ff;
}

.po_icon::before {
  content: '\f059';
  font-family: 'FontAwesome';
  font-style: normal;
}
