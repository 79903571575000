/* $scaffolding
 ------------------------------------------*/

body,
html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background: #6b6b6b;
  letter-spacing: 0.01rem;
}

.app {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  @include media-breakpoint-down(lg) {
    transform: none !important;
  }
}

