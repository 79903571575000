.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-1 {
}

.bg-2 {
}

.bg-3 {
}

.lockcode {
  display: block;
  max-width: 280px;
  margin: 0 auto;
}

.error-number {
  font-family: $font-family-headers;
  font-size: 60px;
  line-height: 1;
}
