//Light theme
$light-theme-background: (
  background: $body-bg,
  header: white,
  sidebar: rgba(51, 51, 51, 1.00),
  brand: rgba(51, 51, 51, 1.00),
  hover: rgba(black, 0.04),
  hover-o: rgba(black, 0.05),
  card: white,
  modal: white,
  secondary-button: white,
  selected-cell: $brand-primary,
  chart-tooltip: rgba(51, 51, 51, 1.00),
);

$light-theme-foreground: (
  base: $body-color,
  header: $body-color,
  header-hover: black,
  sidebar: rgba(white, .56),
  sidebar-hover: white,
  brand: white,
  divider: rgba(black, 0.12),
  disabled: rgba(black, 0.3),
  muted-text: rgba(black, 0.3),
  chart-grid: rgba(black, 0.05),
);
.theme-light {
  @include theme($light-theme-background, $light-theme-foreground);
}

//Dark Theme
$dark-theme-background: (
  background: #3a3a3a,
  header: map_get($mat-grey, 800),
  sidebar: rgba(51, 51, 51, 1.00),
  brand: rgba(51, 51, 51, 1.00),
  hover: rgba(white, 0.04),
  hover-o: rgba(white, 0.05),
  card: map_get($mat-grey, 800),
  modal: map_get($mat-grey, 800),
  secondary-button: map-get($mat-grey, 800),
  selected-cell: $brand-primary,
  chart-tooltip: rgba(51, 51, 51, 1.00),
);

$dark-theme-foreground: (
  base: white,
  header: rgba(white, 0.7),
  header-hover: white,
  sidebar: rgba(white, .56),
  sidebar-hover: white,
  brand: white,
  divider: rgba(white, 0.12),
  disabled: rgba(white, 0.3),
  muted-text: rgba(white, 0.3),
  chart-grid: rgba(white, 0.05),
);

.theme-dark {
  @include theme($dark-theme-background, $dark-theme-foreground);
}
