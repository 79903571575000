.ngx-datatable {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;

  &.fullscreen {
    position: absolute !important;
    height: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
  }

  &.scroll-vertical {
    height: 70vh;
  }

  &:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        transition: background $transition-duration linear;
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {
      &:hover,
      &:hover .datatable-row-group {
        transition: background $transition-duration linear;
      }
    }
  }

  /**
   * Shared Styles
   */
  .empty-row {
    height: 50px;
    text-align: left;
    padding: .5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
  }

  .loading-row {
    text-align: left;
    padding: .5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
  }

  /**
   * Global Row Styles
   */
  .datatable-header,
  .datatable-body {
    .datatable-row-left {
      background-color: white;
      background-position: 100% 0;
      background-repeat: repeat-y;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
    }

    .datatable-row-right {
      background-position: 0 0;
      background-color: white;
      background-repeat: repeat-y;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
    }
  }

  /**
   * Header Styles
   */
  .datatable-header {
    border-bottom: 1px solid $table-border-color;

    .datatable-header-cell {
      text-align: left;
      padding: .5rem 1.2rem;
      font-weight: 400;
      color: #757575;
      vertical-align: bottom;

      .datatable-header-cell-wrapper {
        position: relative;
      }

      &.longpress {
        .draggable::after {
          transition: transform $transition-duration ease, opacity $transition-duration ease;
          opacity: .5;
          transform: scale(1);
        }
      }

      .draggable::after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -30px;
        height: 60px;
        width: 60px;
        background: $table-bg-accent;
        border-radius: 100%;
        opacity: 1;
        filter: none;
        transform: scale(0);
        z-index: 9999;
        pointer-events: none;
      }

      &.dragging {
        .resize-handle {
          border-right: none;
        }
      }
    }

    .resize-handle {
      border-right: solid 1px $table-bg-accent;
    }
  }

  /**
   * Body Styles
   */
  .datatable-body {
    .datatable-row-detail {
      background: #f5f5f5;
      padding: 10px;
    }

    .datatable-body-row {
      .datatable-body-cell {
        text-align: left;
        padding: .5rem 1.2rem;
        vertical-align: top;
        border-top: 0;
        transition: width $transition-duration ease;

        // cell active class
        // &.active {
        //  background: #0829e0
        // }
      }
    }

    .progress-linear {
      display: block;
      position: relative;
      width: 100%;
      height: 5px;
      padding: 0;
      margin: 0;
      position: absolute;

      .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 5px;
        transform: translate(0, 0) scale(1, 1);
        background-color: rgb(170, 209, 249);

        .bar {
          animation: query .8s infinite cubic-bezier(.39, .575, .565, 1);
          transition: transform $transition-duration linear;
          background-color: rgb(16, 108, 200);
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
        }
      }
    }
  }

  /**
   * Footer Styles
   */
  .datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 16px;

    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem;
    }

    .datatable-pager {
      margin: 10px;
      //line-height: 50px;
      box-sizing: border-box;
      max-height: 100%;
      display: flex;
      flex-direction: row;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      justify-content: flex-end;
      align-content: stretch;
      align-items: stretch;
      -webkit-box-align: stretch;
      -webkit-box-pack: end;

      .pager {
        display: flex !important;
        // 1-2: Disable browser default list styles
        padding-left: 0; // 1
        list-style: none; // 2
        @include border-radius();
      }

      .pager li {
        &:first-child {
          a {
            margin-left: 0;
            @include border-left-radius($border-radius);
          }
        }

        &:last-child {
          a {
            @include border-right-radius($border-radius);
          }
        }

        &.active a {
          z-index: 2;
          color: $pagination-active-color;
          background-color: $pagination-active-bg;
          border-color: $pagination-active-border;
        }

        &.disabled {
          color: $pagination-disabled-color;
          background-color: $pagination-disabled-bg;
          border-color: $pagination-disabled-border;
          opacity: .4;

          a {
            pointer-events: none;
            cursor: $cursor-disabled; // While `pointer-events: none` removes the cursor in modern browsers, we provide a disabled cursor as a fallback.
          }
        }
      }

      .pager li a {
        position: relative;
        display: block;
        padding: $pagination-padding-y $pagination-padding-x;
        margin-left: -1px;
        line-height: $pagination-line-height;
        color: $pagination-color;
        background-color: $pagination-bg;
        border: $pagination-border-width solid $pagination-border-color;

        @include hover-focus {
          color: $pagination-hover-color;
          text-decoration: none;
          background-color: $pagination-hover-bg;
          border-color: $pagination-hover-border;
        }

        i {
          height: 100%;
          box-sizing: border-box;
          max-height: 100%;
          display: flex;
          flex-direction: row;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          justify-content: center;
          align-content: center;
          align-items: center;
          -webkit-box-align: center;
          -webkit-box-pack: center;
          font-size: 20px;
        }
      }
    }
  }
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 0;

  input[type='checkbox'] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none;

    &:before {
      transition: all $transition-duration ease-in-out;
      content: "";
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border: 2px solid #f2f2f2;
    }

    &:checked:before {
      transform: rotate(-45deg);
      height: .5rem;
      border-color: #009688;
      border-top-style: none;
      border-right-style: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: white;
      cursor: pointer;
    }
  }
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
