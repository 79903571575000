.table.align-middle th, .table.align-middle td {
  vertical-align: middle;
}

.table th:first-child, .table td:first-child {
  padding-left: $card-spacer-x;
}

.table th:last-child, .table td:last-child {
  padding-right: $card-spacer-x;
}

/* No More tables */
@include media-breakpoint-down(md) {
  /* Source:: http://elvery.net/demo/responsive-tables/#no-more-tables */
  /* Force table to not be like tables anymore */
  .no-more-tables table,
  .no-more-tables thead,
  .no-more-tables tbody,
  .no-more-tables th,
  .no-more-tables td,
  .no-more-tables tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .no-more-tables td {
    position: relative;
    padding-left: 50% !important;
    /* Behave  like a "row" */
    border-top: 0 !important;
    text-align: left;
    white-space: normal;
  }
  .no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0.375rem;
    left: 0.375rem;
    padding-right: 0.625rem;
    width: 45%;
    text-align: left;
    white-space: nowrap;
    font-weight: $font-weight-bold;
  }
  /* Label the data */
  .no-more-tables td:before {
    content: attr(data-title);
  }
}
